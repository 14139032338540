import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import versionService from "../../services/versions";
import braceletService from "../../services/bracelets";

const GamerData = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const gamer = JSON.parse(window.localStorage.getItem("gamer"));

  const [braceletSerialNumber, setBraceletSerialNumber] = useState('');
  const [newBraceletSerialNumber, setNewBraceletSerialNumber] = useState('');


  const [checkBraceletSerialNumber, setCheckBraceletSerialNumber] = useState('');
  const [bracelet, setBracelet] = useState({});
  const [braceletExists, setBraceletExists] = useState(false);
  const [scanModal, setScanModal] = useState(false);

  const [modalError, setModalError] = useState(false);

  const toggleError = () => {
    setModalError(!modalError)
    handleDataClean()
  };

  const [modalError2, setModalError2] = useState(false);

  const toggleError2 = () => {
    setModalError2(!modalError2)
    handleDataClean()
  };
  const [modalExito, setModalExito] = useState(false);

  const toggleExito = () => {
    setModalExito(!modalExito)
    handleDataClean()

    if(modalExito){
      navigate('/home')
    }
    
  };
  const [modalExito2, setModalExito2] = useState(false);

  const toggleExito2 = () => {
    setModalExito2(!modalExito2)
    handleDataClean()
    
    if(modalExito){
      navigate('/home')
    }
    
  };

  const handleDataClean = () => {

    setBraceletSerialNumber('');
    setNewBraceletSerialNumber('');
    setCheckBraceletSerialNumber('')
  }
  const [modalCheck, setModalCheck] = useState(false);

  const toggleCheck = () => setModalCheck(!modalCheck);

  const [formData, setFormData] = useState({
    gamer_id: gamer.id,
    bracelet_id: "",
  });
  const [currentVersion, setCurrentVersion] = useState({});


  const toggleShow = () => setScanModal(!scanModal);


  const handleBraceletDataLoad = async () => {
    const newGamerBracelet = await braceletService.getGamerBraceletByGamerId(
      gamer.id
    );
    if (newGamerBracelet.length > 0) {
      console.log(newGamerBracelet)
      setBracelet(newGamerBracelet[0]);
      setBraceletExists(true);
    }
  };
  const handleGamerBraceletCreation = async () => {
    if (braceletSerialNumber != '') {
      const gamerBracelet = await braceletService.getBraceletByBraceletSerialNumber({
        braceletSerialNumber: braceletSerialNumber,
        version_id: currentVersion.id
      });

      if (gamerBracelet.status != 404) {

        const confirmBraceletIsAssigned = await braceletService.getGamerByBraceletId(gamerBracelet.id)

        if (confirmBraceletIsAssigned.length == 0) {
          const bracelet = gamerBracelet.id
          setFormData(
            {
              ...formData,
              bracelet_id: bracelet
            }
          )

          const newAssignedBracelet = await braceletService.createGamerBracelet({ gamer_id: gamer.id, bracelet_id: bracelet });
          if (newAssignedBracelet.id) {
            toggleShow();
            toggleExito();
          }


        } else {
          toggleShow();
          toggleError();
        }
      }
      else {

        toggleShow();
        toggleError2();
      }
    }
  };

  const handleGamerBraceletUpdate = async () => {
    if (newBraceletSerialNumber != '') {

      //obtiene informacion de la pulsera escaneada
      const newGamerBracelet = await braceletService.getBraceletByBraceletSerialNumber({
        braceletSerialNumber: newBraceletSerialNumber,
        version_id: currentVersion.id
      });

      if (newGamerBracelet.status != 404) {

        //obtiene informacion de la antigua pulsera 
        const oldGamerBracelet = await braceletService.getGamerBraceletByGamerId(gamer.id);

        //confirma si la pulsera escaneada tiene alguna relacion
        const confirmBraceletIsAssigned = await braceletService.getGamerByBraceletId(newGamerBracelet.id)

        if (confirmBraceletIsAssigned.length == 0) {

          const braceletid = newGamerBracelet.id;

          //eliminacion de relacion de antigua pulsera con gamer
          const deleteOldBracelet = await braceletService.deleteGamerBracelet(
            oldGamerBracelet[0].id
          );

          //creacion de nueva relacion de pulsera con gamer
          const newAssignedBracelet = await braceletService.createGamerBracelet({ gamer_id: gamer.id, bracelet_id: braceletid });

          if (newAssignedBracelet.id) {
            toggleShow();
            toggleExito();
            handleBraceletDataLoad();
          }

        } else {
          toggleShow();
          toggleError();
        }
      } else {
        toggleShow();
        toggleError2()
      }
    }
  };

  const version = async () => {
    const vers = await versionService.getCurrentVersion();
    setCurrentVersion(vers)

  }
  // useEffect(()=>{
  //   console.log('Update:'+ newBraceletSerialNumber);

  // },[newBraceletSerialNumber])

  // useEffect(()=>{
  //   console.log('Creacion:'+ braceletSerialNumber);
  // },[braceletSerialNumber])

  const handleNFCReader = async () => {

    try {
      const reader = new NDEFReader();
      await reader.scan();
      reader.onreading = async (event) => {
        if(modalCheck){
          //setBraceletSerialNumber(event.serialNumber);
          setCheckBraceletSerialNumber(event.serialNumber);
        }
        if (scanModal) {
          setBraceletSerialNumber(event.serialNumber);
          setNewBraceletSerialNumber(event.serialNumber);
        }
      }
      reader.onreadingerror = () => {
        alert("Error de lectura NFC");
      }
    } catch (error) {
      //console.log(error)
    }
  }

  useEffect(() => {
    if (modalCheck || scanModal) {
      handleNFCReader();
    } else {
      setBraceletSerialNumber('')
      setCheckBraceletSerialNumber('')
      setNewBraceletSerialNumber('')
    }
  }, [modalCheck, scanModal])

  const checkGamerBracelet = async () => {

    if (checkBraceletSerialNumber != '') {

      const gamerBracelet = await braceletService.getBraceletByBraceletSerialNumber({
        braceletSerialNumber: checkBraceletSerialNumber,
        version_id: currentVersion.id
      });
      if (gamerBracelet.status != 404) {

        const confirmBraceletIsAssigned = await braceletService.getGamerByBraceletId(gamerBracelet.id)

        if (confirmBraceletIsAssigned[0].gamer.id == gamer.id) {
          toggleCheck()
          toggleExito2()
        } else {
          toggleCheck()
          toggleError()
        }
      } else {
        toggleCheck();
        toggleError2()
      }

    } else {
      toggleCheck()
      toggleError2()
    }
  }

  useEffect(() => {
    handleBraceletDataLoad();

    version()
  }, []);


  return (
    <>
      <div className="page-cubes d-flex flex-column min-vh-100">
        <MenuComponent onProfile={false} />
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="d-flex align-items-center py-md-4 px-3 mb-4">
              <MDBCol className="col-md-6 mx-auto border bg-white p-md-5 py-5 px-3">
                <h3 className="fw-bold text-center mb-4">{gamer.gamerFullName}</h3>

                <div className="d-flex align-items-center">
                  <label className="small w-50">RUT:</label>
                  <input
                    className="form-control mb-3"
                    disabled
                    value={gamer.gamerDNI}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label className="small w-50">USUARIO:</label>
                  <input
                    className="form-control mb-3"
                    disabled
                    value={gamer.gamerName}
                  />
                </div>
                {!braceletExists && (
                  <div class="alert alert-danger fs-5 text-center" role="alert">Sin pulsera asignada</div>
                )}
                {braceletExists && (
                  <div className="d-flex align-items-center">
                    <label className="small w-50">SERIE:</label>
                    <input
                      className="form-control mb-3"
                      disabled
                      value={bracelet && bracelet.bracelet.braceletSerialNumber}
                    />
                  </div>
                )}
              </MDBCol>
            </MDBRow>
            {braceletExists && (
              <MDBRow className="d-flex align-items-center mb-2">
                <MDBCol className="text-center col-md-6 mx-auto">
                  <MDBBtn
                    className="button-primary w-100"
                    outline
                    color="dark"
                    onClick={toggleCheck}
                  >
                    Verificar
                  </MDBBtn>
                </MDBCol>{" "}
              </MDBRow>
            )}

            {!braceletExists && (
              <MDBRow className="d-flex align-items-center mb-2">
                <MDBCol className="text-center col-md-6 mx-auto">
                  <MDBBtn
                    className="button-primary mt-auto w-100"
                    color="dark"
                    outline
                    onClick={toggleShow}
                  >
                    ESCANEAR PULSERA
                  </MDBBtn>
                </MDBCol>{" "}
              </MDBRow>
            )}
            {braceletExists && (
              <MDBRow className="d-flex align-items-center mb-2">
                <MDBCol className="text-center col-md-6 mx-auto">
                  <MDBBtn
                    className="button-secondary mt-auto w-100"
                    outline
                    color="dark"
                    onClick={toggleShow}
                  >
                    REEMPLAZAR PULSERA
                  </MDBBtn>
                </MDBCol>{" "}
              </MDBRow>
            )}
            <MDBRow className="d-flex align-items-center mb-2">
              <MDBCol className="text-center col-md-6 mx-auto">
                <MDBBtn
                  className="button-light mt-auto w-100"
                  color="dark"
                  outline
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  VOLVER AL INICIO
                </MDBBtn>
              </MDBCol>{" "}
            </MDBRow>
          </MDBContainer>
        </div>
      </div>

      <MDBModal tabIndex="-1" show={scanModal} setShow={setScanModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="fs-4 fw-bold">Escanear Pulsera</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleCheck} />
            </MDBModalHeader>
            <MDBModalBody>
              {
                !braceletExists &&
                <MDBInput
                  value={braceletSerialNumber}
                  onChange={(e) => {
                    setBraceletSerialNumber(e.target.value);
                  }}
                ></MDBInput>
              }
              {
                braceletExists &&
                <MDBInput
                  value={newBraceletSerialNumber}
                  onChange={(e) => {
                    setNewBraceletSerialNumber(e.target.value);
                  }}
                ></MDBInput>
              }
            </MDBModalBody>
            <MDBModalFooter className="py-4">
              {!braceletExists && (
                <MDBBtn
                  className="button-primary mx-auto px-3 py-0"
                  outline
                  color="dark"
                  onClick={handleGamerBraceletCreation}
                >
                  Asignar Pulsera
                </MDBBtn>
              )}

              {braceletExists && (
                <MDBBtn
                  className="button-secondary py-0 px-3 mb-3 "
                  outline
                  color="dark"
                  onClick={handleGamerBraceletUpdate}
                >
                  Reemplazar Pulsera
                </MDBBtn>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal staticBackdrop tabIndex="-1" show={modalError} setShow={setModalError}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="fs-4 fw-bold">Error</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Esta pulsera ya ha sido asignada a otro jugador</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="button-light py-0 px-3 mb-3 "
                outline
                color="dark"
                onClick={toggleError}
              >
                Volver a intentarlo
              </MDBBtn>

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal staticBackdrop tabIndex="-1" show={modalError2} setShow={setModalError2}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="fs-4 fw-bold">Error</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Esta pulsera no corresponde a este evento o no se encuentra registrada.</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="button-light py-0 px-3 mb-3 "
                outline
                color="dark"
                onClick={toggleError2}
              >
                Volver a intentarlo
              </MDBBtn>

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal staticBackdrop tabIndex="-1" show={modalExito} setShow={setModalExito}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader className="fs-4 fw-bold">Has asignado esta pulsera a:</MDBModalHeader>
            <MDBModalBody>
              <p className="fs-5 mb-1"><b>Nombre:</b> {gamer.gamerFullName}</p>
              <p className="fs-5 mb-1"><b>Usuario:</b> {gamer.gamerName}</p>
              <p className="fs-5"><b>RUT:</b> {gamer.gamerDNI}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="button-primary py-0 px-3 mb-3 "
                outline
                color="dark"
                onClick={toggleExito}
              >
                FINALIZAR
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal tabIndex="-1" show={modalCheck} setShow={setModalCheck}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="fs-4 fw-bold">Verificar Pulsera</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleCheck}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                value={checkBraceletSerialNumber}
                onChange={(e) => {
                  setCheckBraceletSerialNumber(e.target.value);
                }}
              ></MDBInput>
            </MDBModalBody>
            <MDBModalFooter>

              {braceletExists && (
                <MDBBtn
                  className="button-primary py-0 px-3 mb-3 "
                  outline
                  color="dark"
                  onClick={checkGamerBracelet}
                >
                  Verificar
                </MDBBtn>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal staticBackdrop tabIndex="-1" show={modalExito2} setShow={setModalExito2}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader className="fs-4 fw-bold">Has verificado la pulsera de:</MDBModalHeader>
            <MDBModalBody>
              <p className="fs-5 mb-1"><b>Nombre:</b> {gamer.gamerFullName}</p>
              <p className="fs-5 mb-1"><b>Usuario:</b> {gamer.gamerName}</p>
              <p className="fs-5"><b>RUT:</b> {gamer.gamerDNI}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                className="button-primary py-0 px-3 mb-3 "
                outline
                color="dark"
                onClick={toggleExito2}
              >
                Finalizar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </>
  );
};
export default GamerData;
