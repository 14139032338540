import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
  MDBContainer, MDBRow, MDBCol, MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody, MDBInput,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";
import CurrentVersionController from "../../Controller/CurrentVersionController";
const BraceletScanner = () => {

  const navigate = useNavigate();

  const state = JSON.parse(window.localStorage.getItem('token'));
  const [token, setToken] = useState(state.user);
  const user = JSON.parse(atob(token));
  const userId = user.id;
  const [testData, setTestData] = useState('');
  const [modalScanBracelet, setModalScanBracelet] = useState(false)
  const [braceletSerialNumber, setBraceletSerialNumber] = useState('')
  const [braceletId, setBraceletId] = useState('')
  const [gamer, setGamer] = useState({
    id: "",
    braceletId: braceletId,
    gamerDNI: "",
    gamerName: ""
  })
  const handleVersionDataLoad = async () => {
    const verifyCurrentVersion = await CurrentVersionController(userId);
    if (!verifyCurrentVersion) {

      localStorage.removeItem("token")
      navigate('/login');
    }

  }
  handleVersionDataLoad();
  const toggleModalScan = () => {
    setModalScanBracelet(!modalScanBracelet)
    setBraceletId('')
    setGamer({
      ...gamer,
      braceletId: braceletId,
      gamerDNI: "",
      gamerName: ""
    })
  }
  const handleGamerDataLoad = async () => {
    const gamerBracelet = await braceletService.getGamerByBraceletSerialNumber({
      braceletSerialNumber: braceletSerialNumber
    });

    if (gamerBracelet.id === undefined) {
      alert(gamerBracelet.messages.error)
      return;
    }

    const newGamer = gamerBracelet.gamer;
    const newGamerDNI = newGamer.gamerDNI;

    let lastCharacter = newGamerDNI.slice(-1);
    //get first 8 characters
    let firstEightCharacters = newGamerDNI.slice(0, -1);
    //set firstEightCharacters as a number separated by dots (thousands)
    let firstEightCharactersDots = firstEightCharacters.replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      "$1."
    );
    //concatenate firstEightCharactersDots and lastCharacter
    let gamerDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
    const finalGamerDNI = gamerDNIFormatted;
    const finalGamer = {
      ...newGamer,
      gamerDNI: finalGamerDNI,
    }

    setBraceletId(gamerBracelet.bracelet.id);
    setGamer(finalGamer);
  }

  const handleNFCReader = async () => {

    try {
      const reader = new NDEFReader();
      await reader.scan();
      reader.onreading = async (event) => {
        //setBraceletData(event.serialNumber);
        setBraceletSerialNumber(event.serialNumber);
      }
      reader.onreadingerror = () => {
        alert("Error de lectura NFC");
      }
    } catch (error) {
      console.log(error)
    }
  }  

  useEffect(() => {
    if (modalScanBracelet) {
      handleNFCReader();
    } else {
      setBraceletSerialNumber('')
      setBraceletId('')
    }
  }, [modalScanBracelet])

  useEffect(() => {
    if (braceletSerialNumber != '') {
      handleGamerDataLoad()
    }
  }, [braceletSerialNumber])
  return (
    <>
      <div className="page-cubes d-flex flex-column min-vh-100">
        <MenuComponent onProfile={false} />
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">
          <MDBContainer>
            <MDBRow className="d-flex align-items-center py-5">
              <MDBCol className="text-center col-md-5 mb-5 mx-auto">
                <h1 className="fw-bold align-text-center mb-4">Escanear la pulsera del jugador</h1>

                <img
                  src=''
                  className='figure-img img-fluid rounded shadow-3 mb-3'
                  alt='Imagen pulsera'
                />

                <MDBBtn className="button-primary py-0 px-3 mt-auto w-100"
                  onClick={toggleModalScan}
                  outline
                  color="dark">
                  Escanear Pulsera
                </MDBBtn>
              </MDBCol>
            </MDBRow>


          </MDBContainer>
        </div>
      </div>
      <MDBModal show={modalScanBracelet} staticBackdrop setShow={setModalScanBracelet}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Editar Pulsera</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleModalScan} />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                className="mb-3"
                minLength="20"
                label="Codigo pulsera"
                name="braceletSerialNumber"
                value={braceletSerialNumber}
                onChange={(e) => { setBraceletSerialNumber(e.target.value) }}
              />

            </MDBModalBody>
            <MDBModalFooter>

              {gamer.id && <MDBBtn outline color="dark" onClick={() => {
                localStorage.setItem('gamer', JSON.stringify(gamer)),
                  navigate('/gamerData')
              }} >
                Revisar
              </MDBBtn>}
              {testData}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}
export default BraceletScanner;