import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody, MDBInput,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";
import zoneService from "../../services/zones";
import challengeService from "../../services/challenges"

const QRScanner = () => {
    

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(atob(token.user));
    const [zoneId, setZoneId] = useState(null)
    const [zone , setZone ] = useState({
        id:zoneId,
        version_id: "",
        zoneName: "",
        zoneType: ""
    });

    const [challengeId, setChallengeId ] = useState('');
    const [challenge,setChallenge] = useState({
        id:challengeId,
        challengeDescription:"",
        challengeName:"",
        challengeScore:"",
        zone_id:zoneId,
        zone:"",

    });

    const [centredModal, setCentredModal] = useState(false);

    const toggleShow = () => setCentredModal(!centredModal);


    const handleZoneDataLoad = async () =>{
        const user_id = user.id;
        const newZoneByUser = await zoneService.getZoneByUser(user_id)
        if(newZoneByUser[0].user){
            
            setZoneId(newZoneByUser[0].zone.id)
            const newZone = {
                id: zoneId,
                version_id: newZoneByUser[0].zone.version_id,
                zoneName: newZoneByUser[0].zone.zoneName,
                zoneType: newZoneByUser[0].zone.zoneType
            }
            setZone(newZone)

        }
    }
    const handleChallengeDataLoad =  async ()=>{
        if (challengeId != '') {
            
        const newChallengeById = await challengeService.getChallengeById(challengeId);
        
        if (newChallengeById.zone_id == zoneId) {
            const newChallenge = {
              id: newChallengeById.id,
              challengeDescription: newChallengeById.challengeDescription ,
              challengeName: newChallengeById.challengeName ,
              challengeScore: newChallengeById.challengeScore,
              zone_id: zoneId,
              zone: newChallengeById.zone,
            };
            setChallenge(newChallenge)
        }
        }
    }
    

    useEffect(() =>{
        if (zone.id == null) {            
        handleZoneDataLoad();
        }
    },[])
    
    useEffect (() =>{
        if (challengeId !='') {
            handleChallengeDataLoad();
        }
    },[challengeId])
    
    useEffect(() =>{
        if (challenge.id != '')  {    
            
            localStorage.setItem('challenge', JSON.stringify(challenge)) 
        }
    },[challenge])
    
    return (
       <>
        <div className="page-cubes d-flex flex-column text-center min-vh-100">
            <MenuComponent onProfile={false} />
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <MDBContainer>
                    <MDBRow className="d-flex align-items-center py-5">
                        <MDBCol className="text-center col-md-5 mb-5 mx-auto">
                            <MDBBtn className="button-secondary py-0 px-3 mb-3"
                            outline
                            color="dark" onClick={setCentredModal} > Scannear QR</MDBBtn>
                            <h1 className="fw-bold align-text-center">VERIFICAR RETO REALIZADO</h1>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
        <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>SCANNEAR QR</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
           
               <MDBInput 
                    className="mb-3"
                    minLength="20"
                    label="QR"
                    name="challengeId"
                    value={challengeId}
                    onChange={ (e) =>{ setChallengeId(e.target.value)}}
                    />
                    <MDBInput 
                    className="mb-3"
                    minLength="20"
                    label="QROperation"
                    name="challengeScoreOperation"
                    />
             
            </MDBModalBody>
            <MDBModalFooter>
            { challenge.id != ''&&  <MDBBtn className="button-secondary py-0 px-3 mb-3"
                    outline
                    color="dark" onClick={() =>{navigate('/scoreAssignation')}}  > Continuar </MDBBtn>}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
        


       </>
    )

}
//

export default QRScanner;