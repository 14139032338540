import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
  MDBContainer, MDBRow, MDBCol, MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody, MDBInput,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";
import gamerService from "../../services/users"
import CurrentVersionController from "../../Controller/CurrentVersionController";

const DniSearch= () =>{

    const token = JSON.parse(localStorage.getItem('token'));
    const [gamerDNI, setGamerDNI] = useState('');
    const [modalError, setModalError] = useState(false);

    const navigate = useNavigate();
    const toggleShow = () => setModalError(!modalError);
    const user = JSON.parse(atob(token.user));

    const handleGamerSearch = async () =>{
        if (gamerDNI != "") {
          let lastCharacter = gamerDNI.slice(-1);
          //get first 8 characters
          let firstEightCharacters = gamerDNI.slice(0, -1);
          //set firstEightCharacters as a number separated by dots (thousands)
          let firstEightCharactersDots = firstEightCharacters.replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1."
          );
          //concatenate firstEightCharactersDots and lastCharacter
          let gamerDNIFormatted =
            firstEightCharactersDots + "-" + lastCharacter;
          const finalGamerDNI = gamerDNIFormatted;
          const gamer = await gamerService.getGamerByDNI(finalGamerDNI);
          if (gamer.status === 404) {
            toggleShow();
          }else{
            localStorage.setItem('gamer', JSON.stringify(gamer))
            navigate('/gamerData')
          }

          
        }
    }
    return (
        <>
         <div className="page-cubes d-flex flex-column min-vh-100">
          <MenuComponent onProfile={false} />
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <MDBContainer>
              <MDBRow className="d-flex align-items-center py-5">
                <MDBCol className="text-center col-md-5 mb-5 mx-auto border bg-white p-5">
                  <h3 className="fw-bold align-text-center">Ingresa el rut del jugador</h3>
                    <p className="text-center fs-5">Con o sin puntos/guión</p>
                    <MDBInput className="mb-4" label="Ingresa el rut..."
                    name="gamerDNI"
                    value={gamerDNI}
                    onChange={(e) => { setGamerDNI(e.target.value) }}
                    />
                    <MDBBtn className="button-primary py-0 px-3"
                    outline
                    color="dark"
                    onClick={handleGamerSearch}
                    >
                    Buscar Jugador
                  </MDBBtn>
                </MDBCol>
              </MDBRow>


            </MDBContainer>
          </div>
        </div>

        <MDBModal tabIndex='-1' staticBackdrop show={modalError} setShow={setModalError}>
        <MDBModalDialog  centered>
          <MDBModalContent>
            <MDBModalBody>
              <h4 className="fs-4 fw-bold mb-0">
                Usuario no encontrado
              </h4>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn  className="button-primary py-0 px-3"
                outline
                color="dark" onClick={toggleShow}>
                Aceptar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
        
        </>
    )
}
export default DniSearch