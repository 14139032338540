import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";
import zoneService from "../../services/zones";

import challengeService from "../../services/challenges";
import imagenCristal from "../../img/cristal-expogame.png";
import cubo from "../../img/cubo_2023_expogame.png";


const ScoreAssignation = () => {
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(atob(token.user));
  const gamer = JSON.parse(window.localStorage.getItem("gamer"));
  const challenge = JSON.parse(window.localStorage.getItem("challenge"));
  
  const [formData, setFormData] = useState({    
    challenge_id: challenge.id,
    gamer_id: gamer.id,
    challengeScoreAmount: challenge.challengeScore
  })

  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);


  const handleCreateChallengeUserScore = async () =>{
    toggleShow()
    if (formData) {
        const newChallengeUserScore = await challengeService.createChallengeUserScore(formData);
        if (newChallengeUserScore) {
            console.log(newChallengeUserScore);
            localStorage.removeItem("gamer")
            localStorage.removeItem("challenge")
            navigate('/home')
        }
        
    }
  }
  const handleNewScan =  () =>{

    localStorage.removeItem("challenge")
    navigate('/qrscanner')
  }
  console.log(formData);

  return (
    <>
    <div className="page-cubes d-flex flex-column text-center min-vh-100">
      <MenuComponent onProfile={false} />
      <MDBContainer className="w-100 text-center">
        <MDBRow className="text-center d-flex align-items-center py-4">
          <MDBCol className="text-center col-md-6 col-12 mb-3 ">
            <h1 className="fw-bold align-text-center">SE ASIGNARAN:</h1>
          </MDBCol>
          <MDBCol lg="6" sm="12">
            <div className="gamerCurrentScore">
              {challenge.challengeScore}{" "}
              <img
                src={imagenCristal}
                alt="Cristales acumulados"
                className="icon-cristal"
                width={60}
              />
            </div>
            <h5 className="points-title">CRISTALES EXPOGAME</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow className="text-center d-flex align-items-center py-4">
          <MDBCol className="text-center col-md-6 col-12 mb-3 ">
            <h1 className="fw-bold align-text-center">
              Por completar el desafio "{challenge.challengeName}"
            </h1>
          </MDBCol>
        </MDBRow>
        <MDBRow className="text-center d-flex align-items-center py-4">
          <MDBCol className="text-center col-md-6 col-12 mb-3 ">
            <MDBBtn className="button-primary py-0 px-3 " outline color="dark" onClick={toggleShow} >
              Confirmar
            </MDBBtn>
          </MDBCol>
          <MDBCol className="text-center col-md-6 col-12 ">
            <MDBBtn className="button-secondary py-0 px-3" outline color="dark" onClick={handleNewScan}>
              Volver a escanear
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
    <MDBModal staticBackdrop tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBRow className="text-center d-flex align-items-center py-4">
                    <MDBCol className="text-center col-12 mb-3">
                    <p>
                        Has asignado {challenge.challengeScore} cristales a:
                    </p>
                    <h2>
                        {gamer.gamerName}
                    </h2>
                    <p>
                        por completar el reto:
                    </p>
                    <h2>
                    "{challenge.challengeName}"
                    </h2>
                    </MDBCol>
                </MDBRow>
           
             
            </MDBModalBody>
            <MDBModalFooter  >
            <MDBBtn className="button-primary py-0 px-3 mb-3"
                    outline
                    color="dark" onClick={handleCreateChallengeUserScore}  > Finalizar </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>

  );
};
export default ScoreAssignation;
